<template>
  <ValidationObserver tag="form" v-slot="{ invalid }" @submit.prevent="submitSignupForm">
    <section class="flex h-screen sm:h-full w-screen">
      <div class="flex flex-1 bg-no-repeat bg-cover bg-center h-full md:hidden lg:hidden"
        style="background-image: url(/images/Auth/sign-up-bg.svg)"></div>

      <div class="flex flex-col items-center w-7/12 md:w-full lg:w-full">
        <BackToProfileButton route_name="Login" class="absolute right-16 top-10"></BackToProfileButton>
        <div class="flex flex-col justify-center md:justify-evenly md:mt-16 h-full w-11/12 sm:w-72">
          <div class="sm:mt-20 sm:mb-5 md:mt-20 lg:mt-48">
            <header class="mb-20 md:mb-5 xl:mb-4">
              <h2 class="font-main font-bold text-4xl md:text-xl lg:text-xl xl:text-2xl">
                Inscription
              </h2>
              <p class="font-main font-normal text-base text-promy-gray-250 md:text-sm lg:text-sm xl:text-sm">
                Inscrivez-vous pour avoir accès à
                <br class="hidden sm:block" />
                nos services
              </p>
            </header>
            <div class="xl:text-xs flex flex-col">
              <div class="flex mt-12 xl:text-sm mb-4">
                <pro-radio label="Madame" :rules="'required'" :value="form.identifiant_client" @change="change($event)">
                  Madame
                </pro-radio>
                <pro-radio label="Monsieur" :rules="'required'" :value="form.identifiant_client" @change="change($event)">
                  Monsieur
                </pro-radio>
              </div>
              <div class="grid grid-cols-2 sm:grid-cols-1 gap-x-9 gap-y-10">
                <pro-input label="Nom" :placeholder="'Nom'" :rules="'required'" v-model="form.nom" type="text" />
                <pro-input label="Prénom" :placeholder="'Prénom'" :rules="'required'" v-model="form.prenom" type="text" />
                <pro-input label="Email" :placeholder="'Email'" :rules="'required|email'" v-model="form.email"
                  type="email" />
                <pro-input-tel label="Téléphone" :placeholder="'Tel'" :rules="'required'"
                  v-model="form.telephone_mobile" />
                <pro-input label="Mot de passe" :placeholder="'Mot de passe'" :rules="'required|minPassword:8'"
                  v-model="form.password" :type="password_input_type" :with_rule="true" @passwordInputIsValidated="(value) => (password_is_valid = value)
                    " withShowPasswordIcon @updateType="(type) => (password_input_type = type)" />
                <pro-input label="Confirmer mot de passe" :placeholder="'Confirmer mot de passe'"
                  :rules="`required|confirmedBy:${form.password}`" v-model="form.password_confirmation"
                  :type="password_confirmation_input_type" @passwordInputIsValidated="(value) => (password_confirmation_is_valid = value)
                    " withShowPasswordIcon @updateType="(type) => (password_confirmation_input_type = type)
    " />
              </div>
              <AcceptCGU class="mt-10" @isAccept="(value) => {
                form.is_accepted = value
              }
                "></AcceptCGU>
            </div>
          </div>
          <button :disabled="invalid && !password_is_valid && !password_confirmation_is_valid
            " type="submit"
            class="px-16 py-4 xl:px-12 xl:py-3 xl:text-xs bg-promy-green-350 text-white rounded-lg font-semibold text-base w-fit lg:mt-20 md:mt-20 sm:!mt-5 sm:w-full sm:mb-12 disabled:bg-promy-gray-100 disabled:text-promy-gray-200 mt-10">
            Validez
          </button>
        </div>
      </div>
    </section>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  computed: {
    ...mapGetters({
      cgu: 'auth/cgu',
    }),
  },
  data() {
    return {
      password_is_valid: false,
      password_confirmation_is_valid: false,
      password_input_type: 'password',
      password_confirmation_input_type: 'password',
      form: {
        is_accepted: false,
        email: this.$route.query.email,
        nom: '',
        prenom: '',
        telephone_mobile: '',
        password: '',
        password_confirmation: '',
        identifiant_client: '',
        validated_CGU: '',
      },
    }
  },
  methods: {
    change(label) {
      this.form.identifiant_client = label
    },
    async submitSignupForm() {
      if (!this.form.is_accepted) {
        this.toast(
          '',
          "Veuillez accepter les Conditions générales d'utilisation!",
          'warning',
        )
        return
      }
      this.form.validated_CGU = this.cgu.data.id
      try {
        if (this.form.password !== this.form.password_confirmation) {
          this.toast(
            'Inscription',
            'Les mots de passe entrés ne sont pas identiques',
            'warning',
          )
          return
        }
        if (this.form.identifiant_client == '') {
          this.toast('Inscription', 'La désignation est requise', 'warning')
          return
        }
        await axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`)

        await this.$http.post('grand-publics/register', {
          ...this.form,
          ...this.$route.query,
        })
        this.toast(
          'Inscription',
          'Veuillez vérifier votre compte en cliquant sur le lien envoyé par email',
          'success',
        )
        this.$router.push({ name: 'Login' })
      } catch (error) {
        this.toast(
          'Inscription',
          Object.values(error.response.data.errors)[0][0],
          'error',
        )
      }
    },
  },
}
</script>

<style>
</style>
